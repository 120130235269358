<template>
  <div class="modal fade bs-example-modal-lg"
       :id="'service-history-modal-'+id"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Status History
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive-sm">
            <table class="table table-small">
              <thead>
              <tr>
                <th>Changed At</th>
                <th>User</th>
                <th>Change Details</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="item in items" :key="'item_'+item.id">
                <td>{{ item.created_at }}</td>
                <td>{{ item.updatedBy.full_name }}</td>
                <td>
                  <div v-if="item.type === 'case'">
                    Changed status from <case-status :status="item.from" /> to <case-status :status="item.to" />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import CaseStatus from '@/components/status/CaseStatus.vue';
export default {
  props: ['id'],
  components: {CaseStatus},
  data() {
    return {
      modal: null,
      items: []
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('service-history-modal-'+this.id));
  },
  methods: {
    show(type, itemId) {
      this.modal.show();
      this.items = [];

      this.loadData(type, itemId);
    },
    loadData(type, itemId) {
      this.items = [];

      this.axios.get('/status-history', {
          params: {
            type: type,
            item_id: itemId
          }
        })
        .then(res => {
          this.items = res.data.data;
        })
    }
  }
}
</script>