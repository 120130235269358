<template>
  <div @click="showHistory" style="display: contents">
    <span class="badge badge-warning" v-if="status == 1">Request</span>
    <span class="badge badge-dark" v-else-if="status == 2">Open</span>
    <span class="badge badge-warning" v-else-if="status == 3">Ongoing</span>
    <span class="badge badge-success" v-else-if="status == 4">Completed</span>
    <span class="badge badge-danger" v-else-if="status == 5">Cancel</span>
    <span class="badge badge-success" v-else-if="status == 6">Approved</span>
    <span class="badge badge-danger" v-else-if="status == 7">Rejected</span>
  </div>

  <teleport to="body">
    <status-history-modal v-if="id"
                          :id="id"
                          :ref="'statusHistoryModal_'+id" />
  </teleport>

</template>

<script>
import StatusHistoryModal from "@/components/modals/StatusHistoryModal.vue";
export default {
  components: {StatusHistoryModal},
  props: ['status', 'id'],
  methods: {
    showHistory() {
      if (this.id) {
        if (this.status != 1)
          this.$refs['statusHistoryModal_'+this.id].show('case', this.id)
      }
    }
  }
}
</script>