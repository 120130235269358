<template>
  <span class="badge badge-warning" v-if="status == 1">Request</span>
  <span class="badge badge-dark" v-else-if="status == 2">Open</span>
  <span class="badge badge-warning" v-else-if="status == 3">Ongoing</span>
  <span class="badge badge-success" v-else-if="status == 4">Finished</span>
  <span class="badge badge-danger" v-else-if="status == 5">Cancel</span>
</template>

<script>
export default {
  props: ['status']
}
</script>